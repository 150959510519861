import TmapComponent from "./OpenLayer";
//import TmapComponent from "./TmapComponent";
import "./App.css";

//import TmapComponent from "./TmapComponent_back";
//import "./App_back.css";

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ margin: "8px 0" }}>OpenLayer Test</h2>
      </div>
      <div style={{ flex: 1 }}>
        <TmapComponent />
      </div>
    </div>
  );
}

export default App;
