import { useEffect, useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import { XYZ, OSM, WMTS } from "ol/source";
import { fromLonLat, Projection, get as getProjection } from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Icon, Style, Stroke } from "ol/style";
import { Point, LineString } from "ol/geom";
import TileGrid from "ol/tilegrid/TileGrid";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import proj4 from "proj4/dist/proj4";
import { register } from "ol/proj/proj4";
import { getTopLeft } from "ol/extent";
import {
  OverviewMap,
  FullScreen,
  defaults as defaultControls,
} from "ol/control";
import Kompas from "kompas";

let MapObj = null;
let Degree = 0;
const AppKey = "l7xx5e8e0d8bde8d4509b9a11a8bb0292043";

const DefaultIcon =
  "https://tmapapi.sktelecom.com/upload/tmap/marker/pin_b_m_m.png";
const IconStart = "https://topopen.tmap.co.kr/imgs/start.png";
const IconArrive = "https://topopen.tmap.co.kr/imgs/arrival.png";
const IconPoint = "https://topopen.tmap.co.kr/imgs/point.png";

const AlertMsg =
  "navigator.geolocation를 사용할수 없어서\n파인원 주소를 현위치로 사용합니다.\n이유 : 기기미지원 or https가 아닐경우";
const AlertMsg1 =
  "navigator.geolocation.watchPosition를 사용할수 없어서\n실시간으로 위치를 업데이트 할수 없습니다.";

const turnTypeArr = [];
turnTypeArr[0] = "휴게소";
turnTypeArr[1] = "도곽에 의한 점";
turnTypeArr[2] = "타일에 의한 점";
turnTypeArr[3] = "고속도로에 의한 안내없음";
turnTypeArr[4] = "일반도로에 의한 안내없음";
turnTypeArr[5] = "특수한 경우 안내없음";
turnTypeArr[6] = "Y자 오른쪽 안내없음";
turnTypeArr[7] = "Y자 왼쪽 안내없음";
turnTypeArr[11] = "직진";
turnTypeArr[12] = "좌회전";
turnTypeArr[13] = "우회전";
turnTypeArr[14] = "U턴";
turnTypeArr[15] = "P턴";
turnTypeArr[16] = "8시 방향 좌회전";
turnTypeArr[17] = "10시 방향 좌회전";
turnTypeArr[18] = "2시 방향 우회전";
turnTypeArr[19] = "4시 방향 우회전";
turnTypeArr[43] = "오른쪽";
turnTypeArr[44] = "왼쪽";
turnTypeArr[51] = "직진 방향";
turnTypeArr[52] = "왼쪽 차선";
turnTypeArr[53] = "오른쪽 차선";
turnTypeArr[54] = "1차선";
turnTypeArr[55] = "2차선";
turnTypeArr[56] = "3차선";
turnTypeArr[57] = "4차선";
turnTypeArr[58] = "5차선";
turnTypeArr[59] = "6차선";
turnTypeArr[60] = "7차선";
turnTypeArr[61] = "8차선";
turnTypeArr[62] = "9차선";
turnTypeArr[63] = "10차선";
turnTypeArr[71] = "첫번째 출구";
turnTypeArr[72] = "두번째 출구";
turnTypeArr[73] = "첫번째 오른쪽 길";
turnTypeArr[74] = "두번째 오른쪽 길";
turnTypeArr[75] = "첫번째 왼쪽 길";
turnTypeArr[76] = "두번째 왼쪽 길";
turnTypeArr[101] = "오른쪽 고속도로 입구";
turnTypeArr[102] = "왼쪽 고속도로 입구";
turnTypeArr[103] = "전방 고속도로 입구";
turnTypeArr[104] = "오른쪽 고속도로 출구";
turnTypeArr[105] = "왼쪽 고속도로 출구";
turnTypeArr[106] = "전방 고속도로 출구";
turnTypeArr[111] = "오른쪽 도시고속도로 입구";
turnTypeArr[112] = "왼쪽 도시고속도로 입구";
turnTypeArr[113] = "전방 도시고속도로 입구";
turnTypeArr[114] = "오른쪽 도시고속도로 출구";
turnTypeArr[115] = "왼쪽 도시고속도로 출구";
turnTypeArr[116] = "전방 도시고속도로 출구";
turnTypeArr[117] = "오른쪽 방향";
turnTypeArr[118] = "왼쪽 방향";
turnTypeArr[119] = "지하차도";
turnTypeArr[120] = "고가도로";
turnTypeArr[121] = "터널";
turnTypeArr[122] = "교량";
turnTypeArr[123] = "지하차도옆";
turnTypeArr[124] = "고가도로옆";
turnTypeArr[130] = "토끼굴 진입";
turnTypeArr[131] = "1시 방향";
turnTypeArr[132] = "2시 방향";
turnTypeArr[133] = "3시 방향";
turnTypeArr[134] = "4시 방향";
turnTypeArr[135] = "5시 방향";
turnTypeArr[136] = "6시 방향";
turnTypeArr[137] = "7시 방향";
turnTypeArr[138] = "8시 방향";
turnTypeArr[139] = "9시 방향";
turnTypeArr[140] = "10시 방향";
turnTypeArr[141] = "11시 방향";
turnTypeArr[142] = "12시 방향";
turnTypeArr[150] = "졸음쉼터";
turnTypeArr[151] = "휴게소";
turnTypeArr[182] = "왼쪽방향 도착안내";
turnTypeArr[183] = "오른쪽방향 도착안내";
turnTypeArr[184] = "경유지";
turnTypeArr[185] = "첫번째경유지";
turnTypeArr[186] = "두번째경유지";
turnTypeArr[187] = "세번째경유지";
turnTypeArr[188] = "네번째경유지";
turnTypeArr[189] = "다섯번째경유지";
turnTypeArr[191] = "제한속도";
turnTypeArr[192] = "사고다발";
turnTypeArr[193] = "급커브";
turnTypeArr[194] = "낙석주의";
turnTypeArr[200] = "출발지";
turnTypeArr[201] = "도착지";
turnTypeArr[203] = "목적지건너편";
turnTypeArr[211] = "횡단보도";
turnTypeArr[212] = "좌측 횡단보도";
turnTypeArr[213] = "우측 횡단보도";
turnTypeArr[214] = "8시 방향 횡단보도";
turnTypeArr[215] = "10시 방향 횡단보도";
turnTypeArr[216] = "2시 방향 횡단보도";
turnTypeArr[217] = "4시 방향 횡단보도";
turnTypeArr[218] = "엘리베이터";
turnTypeArr[233] = "직진 임시";

const StrokeWidth = 6;
const StrokeOpacity = 1;

const TmapComponent = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentPos, setCurrentPos] = useState(null);
  const [nowPosBtnStart, setNowPosBtnStart] = useState(false);
  const [nowPosBtnArrive, setNowPosBtnArrive] = useState(false);
  const [addrStart, setAddrStart] = useState("");
  const [addrArrive, setAddrArrive] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [searchPOIData, setSearchPOIData] = useState([]);
  const [mapType, setMapType] = useState("tmap");
  const [mapLanguage, setMapLanguage] = useState("ko");
  const [mapLanguageEmap, setMapLanguageEmap] = useState("korean_map");
  const [routeType, setRouteType] = useState("walk");
  const [searchType, setSearchType] = useState("start");
  const [searchBtn, setSearchBtn] = useState(false);
  const [routeInfo, setRouteInfo] = useState([]);

  //마커 삭제
  const MakerRemove = (markerName) => {
    //마커 제거
    console.log("MakerRemove markerName", markerName);
    console.log("MakerRemove MapObj.getLayers()", MapObj.getLayers());

    const DelLayerArr = [];
    MapObj.getLayers().forEach((layer) => {
      if (layer && layer.get("name") === markerName) {
        console.log("MakerRemove layer", layer.get("name"));
        DelLayerArr.push(layer);
      }
    });

    DelLayerArr.forEach((layer) => {
      MapObj.removeLayer(layer);
    });
  };

  //검색 주소 선택
  const HandleSelectAddr = (obj, type, modalAct) => {
    console.log("handleSelectAddr obj", obj);
    console.log("handleSelectAddr searchType", type);

    //모달창 닫기
    if (modalAct) {
      console.log("모달 toggle");
      ToggelSearchModal();
    }
    //기존 마커 삭제
    MakerRemove(type);

    //경로 마커 삭제
    MakerRemove("markerPoint");

    //경로선 삭제
    MakerRemove("markerRoute");

    if (type === "start") {
      setAddrStart(obj);
      //마커 생성
      DrawMaker([obj.coord.lon, obj.coord.lat], IconStart, type);
    } else {
      setAddrArrive(obj);
      //마커 생성
      DrawMaker([obj.coord.lon, obj.coord.lat], IconArrive, type);
    }

    //해당 마커로 이동
    MapObj.getView().setCenter(fromLonLat([obj.coord.lon, obj.coord.lat]));
    MapObj.getView().setZoom(18);
  };

  //form 서브밋
  const HandleSubmit = (event) => {
    console.log("HandleSubmit value", window.$(".address").val());
    const searchAddr = window.$(".address").val();

    if (searchAddr) {
      SearchPOI(searchAddr);
    }

    event.preventDefault();
  };

  //명칭검색
  const SearchPOI = (str) => {
    console.log("SearchPOI str", str);

    setSearchBtn(true);

    window.$.ajax({
      method: "GET",
      url: "https://apis.openapi.sk.com/tmap/pois?version=1&format=json&callback=result",
      async: false,
      data: {
        appKey: AppKey,
        searchKeyword: str,
        reqCoordType: "WGS84GEO", //요청 좌표계 옵셥 설정입니다.
        resCoordType: "WGS84GEO", //응답 좌표계 옵셥 설정입니다.
      },
    })
      .done(function (response) {
        console.log("SearchPOI ajax response", response);
        setSearchPOIData(response.searchPoiInfo.pois.poi);

        setTimeout(() => {
          setSearchBtn(false);
        }, 0.5 * 1000);
      })
      .fail(function (request, status, error) {
        console.log("SearchPOI ajax error", error);
        setSearchPOIData([]);

        setTimeout(() => {
          setSearchBtn(false);
        }, 0.5 * 1000);
      });
  };

  //주소 검색 모달
  const ToggelSearchModal = (type) => {
    console.log("modal search type", type);
    setModalOpen(!modalOpen);
    setSearchPOIData([]);
    window.$(".address").val("");
    if (type) {
      setSearchType(type);
    }
  };

  //현재 위치 가져오기
  const GetNowPos = (type) => {
    console.log("getNowPos searchType", type);

    //로딩바 보여주기
    if (type === "start") {
      setNowPosBtnStart(true);
    } else {
      setNowPosBtnArrive(true);
    }

    setTimeout(() => {
      //위치 정보 가져오기
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          //현재 위치
          console.log("geolocation 값 pos", pos.coords);
          ReverseGeo(pos.coords, type);
        },
        function (err) {
          //파인원 주소
          const pos = { latitude: 37.4818592, longitude: 126.8848149 };
          console.log("고정값 pos", pos);
          alert(AlertMsg);
          ReverseGeo(pos, type);
        }
      );
    }, 1 * 1000);
  };

  //좌표로 주소 추출
  const ReverseGeo = (pos, type) => {
    console.log("LoadGetLonLatFromAddress pos", pos);
    console.log("LoadGetLonLatFromAddress type", type);

    window.$.ajax({
      method: "GET",
      url: "https://apis.openapi.sk.com/tmap/geo/reversegeocoding?version=1&format=json&callback=result",
      async: false,
      data: {
        appKey: AppKey,
        coordType: "WGS84GEO", //응답좌표 타입 옵션 설정 입니다.
        addressType: "A10", //주소타입 옵션 설정 입니다.
        lon: pos.longitude,
        lat: pos.latitude,
      },
    })
      .done(function (response) {
        console.log("ReverseGeo ajax response", response);
        const returnObj = {
          addr: response.addressInfo.fullAddress,
          coord: { lat: pos.latitude, lon: pos.longitude },
        };

        HandleSelectAddr(returnObj, type, false);

        //로딩바 숨기기
        if (type === "start") {
          setNowPosBtnStart(false);
        } else {
          setNowPosBtnArrive(false);
        }
      })
      .fail(function (request, status, error) {
        console.log("ReverseGeo ajax error", error);
      });
  };

  //degree를 radian으로 변환
  const DegreesToRadians = (deg) => {
    var pi = Math.PI;
    return deg * (pi / 180);
  };

  //지도 회전
  const HandleRotation = (type) => {
    if (type === "left") {
      Degree -= 5;
    } else {
      Degree += 5;
    }

    console.log("HandleRotation degree", Degree);
    console.log("HandleRotation MapObj", MapObj);

    MapObj.getView().setRotation(DegreesToRadians(Degree));
  };

  //라인 스타일 선언
  const StrokeStyles = {
    s0: new Stroke({
      width: StrokeWidth,
      color: [6, 5, 13, StrokeOpacity], //블랙
    }),

    s1: new Stroke({
      width: StrokeWidth,
      color: [97, 171, 37, StrokeOpacity], //녹색
    }),

    s2: new Stroke({
      width: StrokeWidth,
      color: [255, 255, 0, StrokeOpacity], //노란색
    }),

    s3: new Stroke({
      width: StrokeWidth,
      color: [232, 117, 6, StrokeOpacity], //주황색
    }),

    s4: new Stroke({
      width: StrokeWidth,
      color: [214, 17, 37, StrokeOpacity], //빨간색
    }),
  };

  //경로검색(보행)
  const RouteSearchWalk = () => {
    window.$.ajax({
      method: "POST",
      url: "https://apis.openapi.sk.com/tmap/routes/pedestrian?version=1&format=json&callback=result",
      async: false,
      data: {
        appKey: AppKey,
        startX: addrStart.coord.lon,
        startY: addrStart.coord.lat,
        endX: addrArrive.coord.lon,
        endY: addrArrive.coord.lat,
        reqCoordType: "WGS84GEO",
        resCoordType: "WGS84GEO",
        startName: "출발지",
        endName: "도착지",
      },
    })
      .done(function (response) {
        console.log("RouteSearchWalk ajax response", response);
        //경로 정보 담기
        setRouteInfo(response.features);

        const geoDataArr = [];
        response.features.forEach((data, index) => {
          console.log("item", data);
          let geometry = data.geometry;

          if (geometry.type === "LineString") {
            geometry.coordinates.forEach((geoData, index) => {
              console.log("geoData", geoData);

              geoDataArr.push(geoData);
            });
          } else {
            geometry.coordinates.forEach((geoData, index) => {
              console.log("geoData", geoData);
              //경로 경유지 마커 그리기
              DrawMaker(geometry.coordinates, IconPoint, "markerPoint");
            });
          }
        });

        console.log("RouteSearchWalk, geoDataArr", geoDataArr);

        //경로선 그리기
        DrawRouteLineWalk(geoDataArr);
      })
      .fail(function (request, status, error) {
        console.log("RouteSearchWalk ajax error", error);
      });
  };

  //경로검색(자동차)
  const RouteSearchCar = () => {
    window.$.ajax({
      method: "POST",
      url: "https://apis.openapi.sk.com/tmap/routes?version=1&format=json&callback=result",
      async: false,
      data: {
        appKey: AppKey,
        startX: addrStart.coord.lon,
        startY: addrStart.coord.lat,
        endX: addrArrive.coord.lon,
        endY: addrArrive.coord.lat,
        reqCoordType: "WGS84GEO",
        resCoordType: "WGS84GEO",
        searchOption: 0, //교통최적+추천 0, 교통최적+무료우선 1, 교통최적+최소시간 2, 교통최적+초보 3, 교통최적+고속도로우선 4, 최단거리+유/무료 10, 이륜차도로우선 12, 교통최적+어린이보호구역 회피 19,
        trafficInfo: "Y", //교통정보 옵션 Y or N
      },
    })
      .done(function (response) {
        console.log("RouteSearchCar ajax response", response);
        //경로 정보 담기
        setRouteInfo(response.features);

        const geoDataArr = [];
        response.features.forEach((data, index) => {
          console.log("item", data);
          let geometry = data.geometry;

          if (geometry.type === "LineString") {
            let traffic = "";
            if (geometry.traffic) {
              traffic = geometry.traffic;
            }

            let geoArr = [];

            geometry.coordinates.forEach((geoData, index) => {
              console.log("geoData", geoData);
              geoArr.push(geoData);
            });

            geoDataArr.push({ geo: geoArr, traffic: traffic });
          } else {
            geometry.coordinates.forEach((geoData, index) => {
              console.log("geoData", geoData);
              //경로 경유지 마커 그리기
              DrawMaker(geometry.coordinates, IconPoint, "markerPoint");
            });
          }
        });

        console.log("RouteSearchCar, geoDataArr", geoDataArr);

        //경로선 그리기
        DrawRouteLineCar(geoDataArr);
      })
      .fail(function (request, status, error) {
        console.log("RouteSearchWalk ajax error", error);
      });
  };

  //경로선 그리기 - 보행
  const DrawRouteLineWalk = (geoDataArr, revers = false) => {
    //좌표 lon, lat 앞뒤 바꾸기
    if (revers) {
      geoDataArr.map(function (l) {
        return l.reverse();
      });
    }

    const polyline = new LineString(geoDataArr).transform(
      "EPSG:4326",
      "EPSG:3857"
    );

    console.log("DrawRouteLineWalk revers", revers);
    console.log("DrawRouteLineWalk polyline", polyline);
    console.log(
      "DrawRouteLineWalk polyline.getCoordinates()",
      polyline.getCoordinates()
    );

    const routeFeature = new Feature({
      type: "route",
      geometry: polyline,
    });

    const vectorSource = new VectorSource({
      features: [routeFeature],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: function (feature) {
        return new Style({ stroke: StrokeStyles["s1"] });
      },
      name: "markerRoute",
    });

    MapObj.addLayer(vectorLayer);

    //지도 스케일 조정
    MapObj.getView().fit(vectorSource.getExtent(), {
      padding: [50, 50, 50, 50],
    });
  };

  //경로선 그리기 - 차량
  const DrawRouteLineCar = (geoDataArr) => {
    /* 
    FlowLine 홝용 
    https://stackoverflow.com/questions/66351764/how-to-set-different-colors-for-a-linestring-openlayers-5
    https://viglino.github.io/ol-ext/examples/style/map.style.gpxline.html
    https://github.com/Viglino/ol-ext/blob/master/examples/style/map.style.gpxline.html
    */
    console.log("DrawRouteLineCar geoDataArr", geoDataArr);

    //교통정보 가공
    const tInfo = [];
    const routeFeatureArr = [];
    let cntGeo = 0;

    geoDataArr.forEach((item) => {
      //feature 생성
      const polyline = new LineString(item.geo).transform(
        "EPSG:4326",
        "EPSG:3857"
      );

      routeFeatureArr.push(
        new Feature({
          type: "route",
          geometry: polyline,
        })
      );

      //교통정보 랩핑
      item.traffic.forEach((trafficData) => {
        console.log("DrawRouteLineCar trafficData", trafficData);

        // [시작index, 마지막index, 혼잡도, 속도]
        for (
          let x = trafficData[0] + cntGeo;
          x <= trafficData[1] + cntGeo;
          x++
        ) {
          tInfo[x] = trafficData[2];
        }
      });

      cntGeo += item.geo.length;
    });

    console.log("DrawRouteLineCar tInfo", tInfo);

    const vectorSource = new VectorSource({
      features: routeFeatureArr,
    });

    //벡터에 설정된 feature 가져오기
    vectorSource.getFeatures().forEach((feature, index) => {
      console.log("DrawRouteLineCar vectorSource index", index);
      console.log("DrawRouteLineCar vectorSource feature", feature);
      console.log(
        "DrawRouteLineCar vectorSource traffic",
        geoDataArr[index].traffic
      );

      //feature에 설정된 좌표 가져오기
      const geometry = feature.getGeometry();
      console.log("DrawRouteLineCar vectorSource geometry", geometry);

      let styles = [];
      let i = 0;

      //각 좌표 영역마다 스타일 설정
      geometry.forEachSegment(function (start, end) {
        // console.log(
        //   "DrawRouteLineCar vectorSource ============================"
        // );
        // console.log("DrawRouteLineCar vectorSource start", start);
        // console.log("DrawRouteLineCar vectorSource end", end);

        let styleObj = StrokeStyles["s0"];

        if (StrokeStyles["s" + tInfo[i]]) {
          styleObj = StrokeStyles["s" + tInfo[i]];
        }

        styles.push(
          new Style({
            geometry: new LineString([start, end]),
            stroke: styleObj,
          })
        );
        i++;
      });

      feature.setStyle(styles);
      console.log("DrawRouteLineCar vectorSource style", "s" + tInfo[index]);
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      name: "markerRoute",
    });

    MapObj.addLayer(vectorLayer);

    //지도 스케일 조정
    MapObj.getView().fit(vectorSource.getExtent(), {
      padding: [50, 50, 50, 50],
    });
  };

  //마커 표시
  const DrawMaker = (pos, icons, title) => {
    const iconFeature = new Feature({
      geometry: new Point(fromLonLat(pos)),
      name: title,
    });

    const iconStyle = new Style({
      image: new Icon({
        src: icons,
      }),
    });

    iconFeature.setStyle(iconStyle);

    const vectorSource = new VectorSource({
      features: [iconFeature],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      name: title,
    });

    MapObj.addLayer(vectorLayer);
  };

  //경로검색
  const HandleRouteSearch = () => {
    if (addrStart && addrArrive) {
      console.log("HandleRouteSearch routeType", routeType);
      console.log("HandleRouteSearch addrStart", addrStart);
      console.log("HandleRouteSearch addrArrive", addrArrive);

      //경로선 삭제
      MakerRemove("markerRoute");

      //경로 마커 삭제
      MakerRemove("markerPoint");

      if (routeType === "walk") {
        //보행
        RouteSearchWalk();
      } else {
        //자동차
        RouteSearchCar();
      }
    }
  };

  const fillzero = function (n, digits) {
    var zero = "";
    n = n.toString();
    if (digits > n.length) {
      for (var i = 0; digits - n.length > i; i++) {
        zero += "0";
      }
    }
    return zero + n;
  };

  //기기 회전 리스닝 -  정지는 kompas.clear();
  const DeviceOrientation = () => {
    if (
      window.DeviceOrientationEvent &&
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      DeviceOrientationEvent.requestPermission()
        .then(function () {
          const kompas = new Kompas();
          kompas.watch();
          kompas.on("heading", function (heading) {
            console.log("DeviceOrientation heading", heading);
            //지도 회전
            MapObj.getView().setRotation((Math.PI / 180) * heading);
          });
        })
        .catch(function (error) {
          alert(`ERROR: ${error.message}`);
        });
    }
  };

  //맵 전체 사이즈
  const FullSize = () => {
    window.scrollTo(0, 0); //모바일에서 제대로 동작하지 않아 추가해줌
    window.$("#root").scrollTop(0);
    window.$("#TMap-wrap").addClass("full");
    MapObj.updateSize();
  };

  //맵 기본 사이즈
  const NormalSize = () => {
    window.$("#TMap-wrap").removeClass("full");
    MapObj.updateSize();
    window.scrollTo(0, 999999); //모바일에서 제대로 동작하지 않아 추가해줌
    window.$("#root").scrollTop(999999);
  };

  //초기 함수
  const Initial = () => {
    const latitude = currentPos.latitude; //위도
    const longitude = currentPos.longitude; //경도

    const attributions =
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; Dr.st3in</a> ';

    //Tmap 타일
    const TMapTileLayer = new TileLayer({
      name: "tileMap",
      title: "T-Map",
      type: "base",
      source: new XYZ({
        attributions: attributions,
        tilePixelRatio: 1,
        tileSize: 256,
        url: "https://topopentile1.tmap.co.kr/tms/1.0.0/hd_tile/{z}/{x}/{-y}.png",
      }),
    });

    //Google map 타일 - hl 언어 설정 (ko, en, ja, zh-CN, zh-TW), lyrs (h : roads only, m : standard roadmap, p : terrain, s : satellite, t : terrain, y : hybrid)
    const GoogleMapTileLayer = new TileLayer({
      name: "tileMap",
      title: "Google",
      source: new XYZ({
        attributions: attributions,
        url:
          "https://mt3.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&s=Ga&hl=" +
          mapLanguage,
      }),
    });

    //Vworld map 타일
    const VWorldMapTileLayer = new TileLayer({
      name: "tileMap",
      title: "VWorld",
      source: new XYZ({
        attributions: attributions,
        url: "https://xdworld.vworld.kr/2d/Base/service/{z}/{x}/{y}.png",
      }),
    });

    //국토지리원 map 타일 - EPSG:5179사용
    proj4.defs(
      "EPSG:5179",
      "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs"
    );
    register(proj4);
    const extent = [-200000.0, -28024123.62, 31824123.62, 4000000.0];
    const resolutions = [
      2088.96, 1044.48, 522.24, 261.12, 130.56, 65.28, 32.64, 16.32, 8.16, 4.08,
      2.04, 1.02, 0.51, 0.255,
    ];
    let epsg_5179 = getProjection("EPSG:5179");
    epsg_5179.setExtent(extent);

    const EMapTileLayer = new TileLayer({
      name: "tileMap",
      title: "emap",
      type: "base",
      source: new XYZ({
        attributions: attributions,
        tileSize: 256,
        projection: "EPSG:5179",
        maxResolution: 1954.597389,
        tileGrid: new WMTSTileGrid({
          origin: getTopLeft(epsg_5179.getExtent()),
          resolutions: resolutions,
        }),
        tileUrlFunction: function (tileCoord, pixelRatio, projection) {
          console.log("tileCoord", tileCoord);

          let z = fillzero(tileCoord[0] + 5, 2);
          let x = tileCoord[1];
          let y = tileCoord[2];

          return (
            "https://map.ngii.go.kr/openapi/Gettile.do?apikey=04trYP9_xwLAfALjwZ-B8g&layer=" +
            mapLanguageEmap +
            "&style=korean&tilematrixset=EPSG%3A5179&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=L" +
            z +
            "&TileCol=" +
            x +
            "&TileRow=" +
            y
          );
        },
      }),
    });

    let MaptileLayer = null;
    if (mapType === "google") {
      MaptileLayer = GoogleMapTileLayer;
    } else if (mapType === "vworld") {
      MaptileLayer = VWorldMapTileLayer;
    } else if (mapType === "emap") {
      MaptileLayer = EMapTileLayer;
    } else {
      MaptileLayer = TMapTileLayer;
    }

    //미니맵
    const overviewMapControl = new OverviewMap({
      // see in overviewmap-custom.html to see the custom CSS used
      className: "ol-overviewmap ol-custom-overviewmap",
      layers: [
        new TileLayer({
          source: new OSM({
            url: "https://topopentile1.tmap.co.kr/tms/1.0.0/hd_tile/{z}/{x}/{-y}.png",
          }),
        }),
      ],
      collapseLabel: "\u00BB",
      label: "\u00AB",
      collapsed: false,
    });

    MapObj = new Map({
      controls: defaultControls().extend([
        overviewMapControl,
        //new FullScreen(),
      ]),
      target: "TMap",
      layers: [MaptileLayer],
      view: new View({
        center: fromLonLat([longitude, latitude]),
        zoom: 18,
      }),
    });

    //클릭 이벤트 추가
    // MapObj.on("click", function (evt) {
    //   const _pos = transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
    //   console.log("Map on click pos", _pos);

    //   //마커 제거
    //   MakerRemove('nowPosition');

    //   //마커 그리기
    //   DrawMaker(_pos, DefaultIcon, "nowPosition");
    // });

    //마커 그리기
    DrawMaker([longitude, latitude], DefaultIcon, "nowPosition");

    //기기 회전 리스닝
    DeviceOrientation();
  };

  useEffect(() => {
    //위치 좌표는 localhost가 아닐경우 https에서만 동작함....
    navigator.geolocation.getCurrentPosition(
      function (pos) {
        //현재 위치
        console.log("pos", pos);
        setCurrentPos(pos.coords);
      },
      function (err) {
        alert(AlertMsg);
        //파인원 주소
        setCurrentPos({ latitude: 37.4818592, longitude: 126.8848149 });
      }
    );
  }, []);

  //초기 위치 좌표가 변경됐을때
  useEffect(() => {
    if (currentPos) {
      setLoading(false);
      Initial();
    }
  }, [currentPos]);

  useEffect(() => {
    console.log("mapType", mapType);
    console.log("mapLanguage", mapLanguage);

    if (MapObj) {
      setAddrStart(""); //출발 정보 초기화
      setAddrArrive(""); //도착 정보 초기화
      setRouteInfo([]); //경로 정보 초기화

      //지도 제거
      MapObj.setTarget(null);
      MapObj = null;

      Initial();
    }
  }, [mapType, mapLanguage, mapLanguageEmap]);

  return (
    <>
      {/* 모달영역 */}
      <dialog open={modalOpen}>
        <article style={{ width: "80%" }}>
          <h3>주소검색</h3>
          <div>
            <form onSubmit={HandleSubmit}>
              <label>
                <input
                  type="text"
                  className="address"
                  name="address"
                  placeholder="주소"
                  required
                />
              </label>

              <button
                aria-busy={searchBtn}
                style={{ width: "140px", margin: "0 auto" }}
                type="submit"
              >
                검색
              </button>
            </form>
          </div>

          <div>
            <label>결과</label>
            <ol className="search-list">
              {searchPOIData.length > 0 &&
                searchPOIData.map((addr, index) => {
                  console.log(addr);

                  const addrStr =
                    addr.upperAddrName +
                    addr.middleAddrName +
                    addr.detailAddrName +
                    addr.name;

                  const coordStr = addr.noorLat + ", " + addr.noorLon;

                  const coord = {
                    lat: addr.noorLat,
                    lon: addr.noorLon,
                  };

                  return (
                    <li key={index}>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <p>주소 : {addrStr}</p>
                          <p>좌표 : {coordStr}</p>
                        </div>

                        <div>
                          <button
                            onClick={() => {
                              HandleSelectAddr(
                                { addr: addrStr, coord: coord },
                                searchType,
                                true
                              );
                            }}
                          >
                            선택
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })}

              {searchPOIData.length === 0 && "데이터 없음"}
            </ol>
          </div>

          <footer style={{ textAlign: "-webkit-right" }}>
            <button
              style={{ width: "100px", margin: "0" }}
              onClick={ToggelSearchModal}
            >
              닫기
            </button>
          </footer>
        </article>
      </dialog>
      {/* 모달영역 */}

      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ flex: 1 }}>
          {/* 주소검색 */}
          <div className="addr-search-box">
            <div className="flex-box">
              <div className="subject">지도 선택 : </div>
              <div>
                <select
                  onChange={(event) => {
                    console.log(event.target.value);
                    setMapType(event.target.value);
                  }}
                  value={mapType}
                >
                  <option value="tmap">티맵</option>
                  <option value="google">구글</option>
                  <option value="vworld">VWorld</option>
                  <option value="emap">국토지리원</option>
                </select>
              </div>
              {mapType === "google" && (
                <div>
                  <select
                    onChange={(event) => {
                      console.log(event.target.value);
                      setMapLanguage(event.target.value);
                    }}
                    value={mapLanguage}
                  >
                    <option value="ko">한국어</option>
                    <option value="en">영어</option>
                    <option value="ja">일본어</option>
                    <option value="zh-cn">중국어(간체)</option>
                    <option value="zh-tw">중국어(번체)</option>
                  </select>
                </div>
              )}

              {mapType === "emap" && (
                <div>
                  <select
                    onChange={(event) => {
                      console.log(event.target.value);
                      setMapLanguageEmap(event.target.value);
                    }}
                    value={mapLanguageEmap}
                  >
                    <option value="korean_map">한국어</option>
                    <option value="english_map">영어</option>
                    <option value="japanese_map">일본어</option>
                    <option value="chinese_map">중국어</option>
                  </select>
                </div>
              )}
            </div>

            <div className="flex-box">
              <div className="subject">경로 선택 : </div>
              <div>
                <select
                  onChange={(event) => {
                    console.log(event.target.value);
                    setRouteType(event.target.value);
                  }}
                >
                  <option value="walk">보행</option>
                  <option value="car">차량</option>
                </select>
              </div>
            </div>

            <div className="flex-box">
              <div className="subject">출발 : </div>
              <div className="flex-1">
                <input
                  type="text"
                  id="addrStart"
                  name="addrStart"
                  value={addrStart.addr ? addrStart.addr : ""}
                  placeholder="출발지"
                  required
                  readOnly
                />
              </div>
              <div>
                <button
                  aria-busy={nowPosBtnStart}
                  className="secondary"
                  onClick={() => {
                    GetNowPos("start");
                  }}
                >
                  현위치
                </button>
              </div>

              <div>
                <button
                  onClick={() => {
                    ToggelSearchModal("start");
                  }}
                >
                  검색
                </button>
              </div>
            </div>
            <div className="flex-box">
              <div className="subject">도착 : </div>
              <div className="flex-1">
                <input
                  type="text"
                  id="addrArrive"
                  name="addrArrive"
                  value={addrArrive.addr ? addrArrive.addr : ""}
                  placeholder="도착지"
                  required
                  readOnly
                />
              </div>
              <div>
                <button
                  aria-busy={nowPosBtnArrive}
                  className="secondary"
                  onClick={() => {
                    GetNowPos("arrive");
                  }}
                >
                  현위치
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    ToggelSearchModal("arrive");
                  }}
                >
                  검색
                </button>
              </div>
            </div>

            <button
              style={{ width: "35%", margin: "20px auto " }}
              onClick={HandleRouteSearch}
            >
              경로검색
            </button>
          </div>
          {/* 주소검색 */}

          {routeInfo.length > 0 && (
            <div>
              <div>
                <h4>
                  ※ 총거리 :
                  {(routeInfo[0].properties.totalDistance / 1000).toFixed(1)}km
                </h4>
              </div>
              <div>
                <h4>
                  ※ 총시간 :{" "}
                  {(routeInfo[0].properties.totalTime / 60).toFixed(0)}분
                </h4>
              </div>
              <div>
                <h4>※ 경로 정보 :</h4>
                <ul
                  style={{
                    height: "200px",
                    overflowY: "auto",
                    border: "2px solid",
                    borderStyle: "dotted",
                  }}
                >
                  {routeInfo.map((data, index) => {
                    if (data.geometry.type === "Point") {
                      return (
                        <li key={index}>
                          {" - "}
                          {data.properties.description}
                          {data.properties.turnType &&
                            " : " + turnTypeArr[data.properties.turnType]}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          )}

          {/* 회전 */}
          <div className="flex-box">
            <div className="rotationBtn">
              <button
                className="btn-small"
                onClick={() => {
                  HandleRotation("left");
                }}
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
              </button>
            </div>
            <div className="rotationBtn">
              <button
                className="btn-small"
                onClick={() => {
                  HandleRotation("right");
                }}
              >
                <i class="fa fa-repeat" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          {/* 회전 */}
        </div>
        <div id="TMap-wrap">
          {!loading && (
            <>
              <button
                className="btn-small normalSizeBtn"
                onClick={() => {
                  NormalSize();
                }}
              >
                <i class="fa fa-compress" aria-hidden="true"></i>
              </button>

              <button
                className="btn-small fullSizeBtn"
                onClick={() => {
                  FullSize();
                }}
              >
                <i class="fa fa-expand" aria-hidden="true"></i>
              </button>
            </>
          )}

          <div id="TMap">
            {loading && <div className="loading">위치 정보 가져오는중...</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default TmapComponent;
